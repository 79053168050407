/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useSubNav } from './';

const SubNavButton = (props) => {
	const { isOpen, setIsOpen, isDesktop, subPages } = useSubNav();

	return (
		!isDesktop &&
		!!subPages &&
		!!subPages.length && (
			<button
				onClick={() => setIsOpen(!isOpen)}
				css={(theme) => [
					theme.resetButton,
					css`
						pointer-events: all;
						width: 1.2em;
						height: 1.2em;
						transform: rotate(${isOpen ? 90 : 0}deg);
						margin: ${theme.dimension.header.spacing.s.y} ${theme.dimension.header.spacing.s.x};

						@media (min-width: ${theme.breakpoint.m}) {
							margin: ${theme.dimension.header.spacing.m.y} ${theme.dimension.header.spacing.m.x};
						}
					`,
				]}
				title="open"
				{...props}
			>
				<svg
					width="18"
					height="18"
					viewBox="0 0 18 18"
					css={css`
						display: block;
						width: 100%;
						height: auto;
					`}
				>
					<path fill="none" stroke="currentColor" strokeWidth="2" d="M0 1H18 M0 9H18M0 17H18" />
				</svg>
			</button>
		)
	);
};

export default SubNavButton;
