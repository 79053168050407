/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Logo from './Logo';
import LanguageNavigation from '../navigation/LanguageNavigation';

const Header = ({ children }) => {
	return (
		<div
			css={(theme) => css`
				pointer-events: none;
				position: fixed;
				z-index: ${theme.zIndex.header};
				left: 0;
				top: 0;
				right: 0;
				height: ${theme.dimension.header.height.s};
				display: flex;
				align-items: flex-start;

				@media (min-width: ${theme.breakpoint.m}) {
					height: ${theme.dimension.header.height.m};
				}
			`}
		>
			<div>{children}</div>
			<div
				css={css`
					position: absolute;
					right: 3em;
					top: 0;
					z-index: -1;
					color: inherit;

					&:visited,
					&:active {
						color: inherit;
					}
				`}
			>
				<Logo />
			</div>
			<LanguageNavigation />
		</div>
	);
};

export default Header;
