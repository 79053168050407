import { css, useTheme } from '@emotion/react';
import { createContext, useMemo, useState, useContext, useEffect } from 'react';

import useMatchMedia from '../../hooks/matchMedia';
import { useNavigation, usePage } from '../../api/use';

export const navItemInnerStyle = (theme, size = 'l', isUppercase = true) => [
	theme.typography.getStyle({ theme, size, lineHeight: 1 }),
	css`
		display: inline-block;
		height: 0.75em;
		margin-top: -0.025em;
		margin-bottom: 1px;
		text-transform: ${isUppercase ? 'uppercase' : 'initial'};
	`,
];

export const navItemLinkStyle = (theme, linkingMode = 'link') => css`
	pointer-events: all;
	line-height: 0;
	padding: ${theme.dimension.header.spacing.s.y} ${theme.dimension.header.spacing.s.x};
	background: ${linkingMode === 'link' ? 'transparent' : theme.color.primary};
	color: ${linkingMode === 'link' ? theme.color.fg : theme.color.primaryContrast};
	display: inline-block;

	@media (min-width: ${theme.breakpoint.m}) {
		padding: ${theme.dimension.header.spacing.m.y} ${theme.dimension.header.spacing.m.x};
	}

	&:visited {
		background: ${linkingMode === 'link' ? 'transparent' : theme.color.primary};
		color: ${linkingMode === 'link' ? theme.color.fg : theme.color.primaryContrast};
	}

	&:focus,
	&:active,
	&:hover {
		outline: none;
		background: ${theme.color.primary};
		color: ${theme.color.primaryContrast};
	}
`;

export const SubNavContext = createContext({
	state: {},
	actions: {},
});

export const SubNavProvider = ({ children }) => {
	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width:${theme.breakpoint.mobileNav})`);

	const page = usePage();
	const { pages } = useNavigation();

	const mainPage = useMemo(() => {
		if (!pages || !page.vitality.loaded) {
			return null;
		}

		let mainPage = pages.find((p) => p.id === page.parentId || p.id === page.id);
		const findPage = (parentId) => pages.find((p) => p.id === parentId);
		while (!!mainPage && mainPage.parentId !== 0) {
			mainPage = findPage(mainPage.parentId);
		}

		return mainPage;
	}, [page, pages]);

	const subPages = useMemo(() => {
		if (!mainPage) return null;
		return pages.filter((p) => p.className !== 'NetworkCategory' && p.parentId === mainPage.id);
	}, [mainPage, pages]);

	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		setIsOpen(false);
	}, [page, setIsOpen]);

	useEffect(() => {
		if (isOpen) {
			window.scrollTo(0, 0);
		}
	}, [isOpen]);

	return (
		<SubNavContext.Provider
			value={{
				mainPage,
				subPages,
				isOpen,
				setIsOpen,
				isDesktop,
			}}
		>
			{children}
		</SubNavContext.Provider>
	);
};

export const useSubNav = () => useContext(SubNavContext);
