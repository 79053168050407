import axios from 'axios';

const filterFlush = (path) => {
	if (path.indexOf('flush=') > 0) {
		const [base, query] = path.split('?');
		const params = new URLSearchParams(query);
		params.delete('flush');
		params.delete('urlspecialstoken');
		const filteredQuery = params.toString();
		return filteredQuery ? `${base}?${query}` : base;
	}
	return path;
};

export const getApiUrl = (path) => {
	return filterFlush(`/api${path}`.replace('//', '/'));
};

export const fetchData = async (path) => {
	const apiUrl = getApiUrl(path);
	try {
		const res = await axios.get(apiUrl, { withCredentials: true });
		return res.data;
	} catch (error) {
		if (error.response) {
			if (
				error.response.status === 404 ||
				error.response.status === 500 ||
				(error.response.data && error.response.data.id)
			) {
				return error.response.data;
			}
		}
		throw new Error('There was an unexpected error');
	}
};

export const fetchNavigation = (path) => {
	return fetchData(`/navigation/${path}`);
};

export const fetchSite = (path) => {
	return fetchData(`/site/${path}`);
};

export const fetchPage = (path) => {
	return fetchData(`/url/${path}`);
};

export const fetchInitial = (path) => {
	return fetchData(`/initial/${path}`);
};
