import { useContext, useEffect, useState, useCallback } from 'react';

import ApiContext from './context';
export const useSite = () => {
	const {
		state: { site },
	} = useApi();

	return site;
};

let i18Initialized = false;
export const useTranslation = () => {
	const { locale, translationTerms } = useSite();
	const [initialized, setInitialized] = useState(i18Initialized);
	useEffect(() => {
		if (!translationTerms) return;
		setInitialized(true);
	}, [locale, translationTerms, setInitialized]);
	const t = useCallback((key) => !!translationTerms && translationTerms[key], [translationTerms]);
	return {
		t,
		locale,
		initialized,
	};
};

export const useNavigation = () => {
	const {
		state: { navigation },
	} = useApi();

	return navigation;
};

export const usePage = () => {
	const {
		state: { page },
	} = useApi();

	return page;
};

export const useNavigate = () => {
	const {
		actions: { navigate },
	} = useApi();

	return navigate;
};

export const useHomeHref = () => {
	const { locale, lang } = useSite();
	return !lang ? '/' : (lang.find((item) => locale === item.locale) || { home: '/' }).home;
};

const useApi = () => {
	const ctx = useContext(ApiContext);
	return ctx;
};

export default useApi;
