/** @jsxImportSource @emotion/react */
import { ThemeProvider, Global } from '@emotion/react';
import { HelmetProvider } from 'react-helmet-async';
import { watchModals } from '@react-aria/aria-modal-polyfill';
import { OverlayProvider } from 'react-aria';

import ApiProvider from './api/provider';
import { SubNavProvider } from './components/navigation';

import ErrorBoundary from './ErrorBoundary';
import PageWrap from './views/PageWrap';

import { theme, globalStyles } from './theme';

import Header from './components/layout/Header';
import NavButton from './components/navigation/NavButton';

const App = () => {
	watchModals();
	return (
		<HelmetProvider>
			<ThemeProvider theme={theme}>
				<Global styles={globalStyles} />
				<ErrorBoundary>
					<OverlayProvider>
						<ApiProvider>
							<SubNavProvider>
								<Header>
									<NavButton />
								</Header>
								<PageWrap />
							</SubNavProvider>
						</ApiProvider>
					</OverlayProvider>
				</ErrorBoundary>
			</ThemeProvider>
		</HelmetProvider>
	);
};

export default App;
