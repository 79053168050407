import { useState, useEffect } from 'react';

function useMatchMedia(query) {
	const [matches, setMatches] = useState(typeof window !== 'undefined' && window.matchMedia(query).matches);

	useEffect(
		function mpEffect() {
			if (!typeof window === 'undefined') {
				return false;
			}

			function handleChange({ matches }) {
				setMatches(matches);
			}

			const mq = window.matchMedia(query);
			// Polyfill does not cover the addEventListener for media queries
			const isLegacy = !('addEventListener' in mq);
			if (isLegacy) {
				mq.addListener(handleChange);
			} else {
				mq.addEventListener('change', handleChange);
			}

			handleChange(mq);

			return () => (isLegacy ? mq.removeListener(handleChange) : mq.removeEventListener('change', handleChange));
		},
		[query]
	);

	return matches;
}

export default useMatchMedia;
