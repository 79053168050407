/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useRef, Fragment, memo, useState, useEffect } from 'react';
import { FocusScope, OverlayContainer, useButton, useDialog, useModal, useOverlay, usePreventScroll } from 'react-aria';

import { usePage, useTranslation, useHomeHref } from '../../api/use';

import { navItemInnerStyle, navItemLinkStyle } from './';
import Nav from './Nav';
import NavLink from './NavLink';
import BreadCrumbs from './BreadCrumbs';
import Header from '../layout/Header';
import SubNavButton from './SubNavButton';
import SubNav from './SubNav';
import Flower from '../Flower';

const ModalDialog = ({ children, ...props }) => {
	const ref = useRef();
	const { overlayProps, underlayProps } = useOverlay(props, ref);
	usePreventScroll();
	const { modalProps } = useModal();
	const { dialogProps } = useDialog(props, ref);

	return (
		<div
			css={(theme) => css`
				pointer-events: all;
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				overflow-x: hidden;
				z-index: ${theme.zIndex.mobileNav};
				background-color: ${theme.color.bg};
			`}
			{...underlayProps}
		>
			<FocusScope contain restoreFocus autoFocus>
				<div {...overlayProps} {...dialogProps} {...modalProps} ref={ref}>
					{children}
				</div>
			</FocusScope>
		</div>
	);
};

const NavButton = () => {
	const homeHref = useHomeHref();
	const { id } = usePage();
	const { initialized, t } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);

	const openButtonRef = useRef();
	const closeButtonRef = useRef();

	const { buttonProps: openButtonProps } = useButton(
		{
			onPress: () => setTimeout(() => setIsOpen(true), 100),
		},
		openButtonRef
	);

	const { buttonProps: closeButtonProps } = useButton(
		{
			onPress: () => setIsOpen(false),
		},
		closeButtonRef
	);

	useEffect(() => {
		setIsOpen(false);
	}, [id, setIsOpen]);

	return (
		!!initialized && (
			<Fragment>
				<div
					css={css`
						display: flex;
						flex-direction: column;
						align-items: flex-start;
					`}
				>
					<button
						{...openButtonProps}
						ref={openButtonRef}
						css={(theme) => [theme.resetButton, navItemLinkStyle(theme)]}
					>
						<span css={navItemInnerStyle}>{t('nav.title')}</span>
					</button>
					<nav
						css={css`
							display: flex;
							flex-direction: column;
							align-items: flex-start;
						`}
					>
						<BreadCrumbs />
						<SubNavButton />
						<SubNav forDesktop />
					</nav>
				</div>
				{isOpen && (
					<OverlayContainer>
						<ModalDialog title={t('nav.title')} onClose={() => setIsOpen(false)} isDismissable>
							<Header>
								<nav
									css={css`
										display: flex;
										flex-direction: column;
										align-items: flex-start;
									`}
								>
									<div
										css={(theme) => css`
											height: ${theme.dimension.header.height.s};

											@media (min-width: ${theme.breakpoint.m}) {
												height: auto;
											}
										`}
									>
										<NavLink
											href={homeHref}
											label={t('nav.close')}
											closeButtonProps={closeButtonProps}
											closeButtonRef={closeButtonRef}
											css={(theme) => [theme.resetButton, navItemLinkStyle(theme)]}
										/>
									</div>
									<Nav closeButtonProps={closeButtonProps} closeButtonRef={closeButtonRef} />
									<Flower />
								</nav>
							</Header>
						</ModalDialog>
					</OverlayContainer>
				)}
			</Fragment>
		)
	);
};

export default memo(NavButton);
