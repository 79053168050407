import { useSubNav } from './';
import NavLink from './NavLink';

const BreadCrumbs = ({ ...props }) => {
	const { mainPage } = useSubNav();
	return (
		!!mainPage && (
			<NavLink
				href={mainPage.link}
				label={mainPage.menuTitle}
				isUppercase={mainPage.className !== 'ContactPage'}
				{...props}
			/>
		)
	);
};

export default BreadCrumbs;
