/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const Logo = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		viewBox="0 0 170.451 94.727"
		css={(theme) => css`
			display: block;
			fill: currentColor;
			width: ${theme.dimension.header.logoWidth.s};

			@media (min-width: ${theme.breakpoint.m}) {
				margin-top: calc(-1 * ${theme.dimension.header.spacing.m.y});
				width: ${theme.dimension.header.logoWidth.m};
			}
		`}
		{...props}
	>
		<path d="M169.66 22.678v-7.997h-48.637V8.778h39.985V.781h-49.095V20.59h-7.404v48.082c0 12.35-6.883 17.614-18.322 17.614-11.945 0-18.93-5.264-18.93-17.614V20.59h-7.623v-5.064h24.092V7.529H26.432v7.997h24.092v10.78c-4.301-5.449-11.316-8.705-22.089-8.705-14.678 0-24.598 7.187-24.598 19.132 0 13.665 10.932 17.31 23.687 20.144 12.957 2.834 21.662 5.162 21.662 15.082 0 9.009-7.187 13.565-17.209 13.565-13.665 0-21.156-6.378-22.371-18.626H.698c.81 14.576 10.528 25.913 30.874 25.913 12.07 0 21.405-4.554 25.006-12.906h3.056v-.728c3.66 10.516 13.746 14.902 26.249 14.902 14.386 0 25.97-6.032 27.547-20.923h7.135v13.901h9.11V52.945h33.303v-7.693h-33.303v-6.208h24.652v-7.693h-24.652v-8.673h39.985zM31.269 49.082C20.438 46.957 12.34 44.527 12.34 36.328c0-7.491 6.478-11.641 16.297-11.641 10.629 0 17.31 5.163 18.626 15.488h3.261v15.502c-5.018-3.492-11.971-5.127-19.255-6.595z" />
	</svg>
);

export default Logo;
