/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { Typography } from './typography';

const ErrorScreen = () => (
	<div
		css={(theme) => css`
			background: ${theme.primary};
			color: ${theme.primaryContrast};
			margin: 0;
			padding: 0;
			z-index: ${theme.zIndex.loadingScreen};
		`}
	>
		<div
			aria-label="error"
			css={css`
				width: 100%;
				height: 100%;
				background: transparent;
				margin: 0;
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;
			`}
		>
			<Typography>
				There was an error.
				<br />
				Please <a href="./">reload.</a>
			</Typography>
		</div>
	</div>
);

export default ErrorScreen;
