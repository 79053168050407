import { useNavigation } from '../../api/use';

import NavLink from './NavLink';

const Nav = ({ closeButtonProps, closeButtonRef }) => {
	const { hierarchy } = useNavigation();
	return (
		!!hierarchy &&
		hierarchy.map((page) => (
			<NavLink
				key={page.id}
				href={page.link}
				label={page.menuTitle}
				closeButtonProps={closeButtonProps}
				closeButtonRef={closeButtonRef}
				isUppercase={page.className !== 'ContactPage'}
			/>
		))
	);
};

export default Nav;
