/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo } from 'react';

import { useSite, usePage } from '../../api/use';

import NavLink from './NavLink';

const LanguageNavigation = () => {
	const { lang, locale: siteLocale } = useSite();
	const { lang: pageLang } = usePage();

	return (
		<nav
			css={css`
				margin-left: auto;
				display: flex;
				flex-direction: column;
			`}
		>
			{!!lang &&
				lang.map(({ locale, label, home }) => (
					<NavLink
						key={locale}
						ignoreSection
						size="s"
						isCurrent={locale === siteLocale}
						label={label}
						href={(pageLang && pageLang.find(({ locale: l }) => l === locale)?.link) || home}
					/>
				))}
		</nav>
	);
};

export default memo(LanguageNavigation);
