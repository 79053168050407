/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useTranslation } from '../../api/use';
import NavLink from './NavLink';
import { useSubNav } from './';

const SubNav = ({ forDesktop = false, ...props }) => {
	const { t } = useTranslation();
	const { mainPage, subPages, isOpen, isDesktop } = useSubNav();
	return !subPages || !subPages.length || forDesktop !== isDesktop || (!forDesktop && !isOpen) ? null : (
		<div {...props}>
			<div
				css={(theme) => [
					css`
						display: flex;
						flex-wrap: wrap;
						gap: 0 1em;
					`,
					!forDesktop
						? css`
								margin-bottom: 1.1em;
								flex-direction: column;
								align-items: flex-start;
						  `
						: css`
								max-width: calc(100vw - ${theme.dimension.header.logoWidth.m});
						  `,
				]}
			>
				{subPages.map((p) => (
					<NavLink key={p.id} label={p.menuTitle} href={p.link} size={forDesktop ? 's' : 'l'} />
				))}
				<NavLink label={t('nav.all')} href={mainPage.link} size={forDesktop ? 's' : 'l'} ignoreSection />
			</div>
		</div>
	);
};

export default SubNav;
