/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo, useCallback, useState } from 'react';
import { useHover, usePress } from 'react-aria';
import { useInView } from 'react-intersection-observer';

import { useSite, useNavigate } from '../api/use';

const Flower = memo(({ isStatic = false }) => {
	const { ref, inView } = useInView();
	const navigate = useNavigate();
	const { contact } = useSite();
	const [active, setActive] = useState(false);

	const goto = useCallback(() => {
		if (isStatic) return;
		setActive(false);
		if (!contact) return;
		setTimeout(() => {
			navigate(contact, {});
		}, 100);
	}, [navigate, contact, setActive, isStatic]);

	const { hoverProps, isHovered } = useHover({
		onHoverStart: (e) => !isStatic && setActive(true),
		onHoverEnd: (e) => !isStatic && setActive(false),
	});

	const { pressProps } = usePress({
		onPress: (e) => {
			if (isStatic) return;
			if (active) {
				goto();
			} else {
				setActive(true);
			}
		},
	});

	return (
		<div
			css={(theme) => css`
				position: relative;
				z-index: -1;

				@media (min-width: ${theme.breakpoint.mobileNav}) {
					position: fixed;
					width: 90%;
					max-width: 80em;
					left: 25%;
					top: 10em;
				}
			`}
		>
			<div
				ref={ref}
				css={(theme) => css`
					position: absolute;
					display: block;
					width: 100vw;
					overflow: hidden;

					@media (min-width: ${theme.breakpoint.mobileNav}) {
						position: static;
						width: auto;
					}
				`}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					xmlSpace="preserve"
					viewBox="0 0 2075.6 1888.705"
					css={(theme) => css`
						display: block;
						margin: 0 -3em;
						opacity: ${isHovered ? 1 : 0.3};

						@media (min-width: ${theme.breakpoint.mobileNav}) {
							margin: 0;
						}
					`}
				>
					<symbol id="flower-deinsstuff" viewBox="-223.418 -34.039 446.835 68.077">
						<path
							fill="#FFF"
							d="M-106.948 23.643h8.095v9.107h-8.095zm0-56.393h8.095v46.733h-8.095zm-11.869 31.738c-2.392 10.027-9.844 16.283-20.423 16.283-13.616 0-21.711-10.211-21.711-24.011 0-15.271 8.188-25.299 21.987-25.299 11.959 0 19.043 7.084 20.607 15.271h-8.28c-1.104-5.243-5.52-9.016-12.327-9.016-7.636 0-13.523 5.244-13.892 16.743h34.958c.001 4.049-.275 7.361-.919 10.029zm-33.946-3.863c.828 8.924 6.072 13.891 13.523 13.891 7.175 0 12.511-4.783 13.339-13.891h-26.862zm90.432 19.871c-6.256 0-11.315-2.853-14.167-7.176h-.184v6.163h-7.819V-32.75h8.095v26.77c0 9.384 5.52 14.352 12.144 14.352 6.716 0 10.764-3.496 10.764-13.247V-32.75h8.095v28.519c-.001 12.786-6.256 19.227-16.928 19.227zM129.671-32.75h8.464v28.058h30.542v7.544h-30.542v22.355h32.566v7.543h-41.03zm93.747 57.957v7.543h-41.031v-65.5h8.464v28.058h30.542v7.544h-30.542v22.355zM55.704-18.859V7.543h9.291v6.439h-9.291v11.315h-8.096V13.983h-8.096v-6.44h8.096v-27.23c0-9.659 3.771-13.432 12.695-13.432 1.656 0 3.771.185 5.152.553v6.623c-.828-.275-2.116-.46-3.312-.46-4.691 0-6.439 2.208-6.439 7.544zm49.954 6.071c0-9.383-5.611-14.351-12.144-14.351-6.715 0-10.763 3.495-10.763 13.247v27.874h-8.096v-28.519c0-12.787 6.163-19.227 16.835-19.227 6.256 0 11.407 2.852 14.259 7.176h.185v-6.164h7.819v46.733h-8.096v-26.769zM11.728 4.875C.964 7.543-4.095 9.568-4.095 16.559c0 6.256 4.875 10.212 12.971 10.212 9.66 0 14.995-4.6 14.995-14.26h8.556c0 14.167-7.819 21.527-23.458 21.527-13.34 0-21.711-7.452-21.711-18.031 0-13.155 9.383-16.283 21.987-19.411C19.823-6.072 25.25-7.912 25.25-15.179s-5.243-11.592-14.627-11.592C.78-26.771-5.2-21.803-5.2-11.04h-8.739c0-15.087 9.199-22.999 24.471-22.999 14.259 0 23.459 7.544 23.459 19.319C33.99-1.288 24.147 1.84 11.728 4.875zM-203.455 32.75h-19.963v-65.5h19.963c21.711 0 33.946 11.775 33.946 32.751 0 20.974-12.235 32.749-33.946 32.749zm-.092-57.957h-11.407v50.413h11.407c16.375 0 25.115-8.831 25.115-25.206s-8.74-25.207-25.115-25.207z"
						/>
					</symbol>
					<symbol id="flower-yourstuff" viewBox="-223.326 -34.038 446.652 68.077">
						<path
							fill="#FFF"
							d="M-56.993 6.808h-.184v7.175h-7.728V-32.75h8.095v23.827c0 10.304 4.968 16.007 13.799 16.007 1.012 0 2.208-.184 3.22-.276v7.268c-1.012.276-2.392.368-3.404.368-5.979-.001-10.67-2.485-13.798-7.636zm-30.359-19.595c0-9.383-5.612-14.351-12.144-14.351-6.716 0-10.764 3.496-10.764 13.247v27.875h-8.095v-28.519c0-12.787 6.164-19.227 16.835-19.227 6.256 0 11.407 2.852 14.259 7.175h.184v-6.164h7.819v46.733h-8.095v-26.769zm-63.755 28.058c-13.708 0-22.171-9.383-22.171-24.655 0-15.271 8.463-24.655 22.171-24.655 13.707 0 22.263 9.384 22.263 24.655 0 15.272-8.556 24.655-22.263 24.655zm0-42.685c-8.464 0-13.708 6.899-13.708 18.031s5.244 18.031 13.708 18.031c8.555 0 13.799-6.9 13.799-18.031s-5.244-18.031-13.799-18.031zm-26.221 60.164-8.096-13.523c-3.404-5.704-6.716-11.5-9.936-17.295h-.184c-3.22 5.796-6.532 11.591-9.936 17.295l-8.003 13.523h-9.843l23.643-38.362V-32.75h8.463v27.139l23.643 38.362h-9.751zm400.654-7.543v7.543h-41.03v-65.5h8.464v28.058h30.542v7.544H190.76v22.355zM129.58-32.75h8.464v28.058h30.542v7.544h-30.542v22.355h32.566v7.543h-41.03zm-24.011 19.963c0-9.383-5.612-14.351-12.144-14.351-6.716 0-10.764 3.496-10.764 13.247v27.875h-8.095v-28.519c0-12.787 6.164-19.227 16.835-19.227 6.256 0 11.407 2.852 14.259 7.175h.184v-6.164h7.819v46.733h-8.095v-26.769zM11.638 4.876C.875 7.544-4.185 9.568-4.185 16.559c0 6.255 4.876 10.211 12.972 10.211 9.659 0 14.995-4.6 14.995-14.259h8.556c0 14.167-7.82 21.527-23.459 21.527-13.339 0-21.711-7.452-21.711-18.031 0-13.155 9.384-16.283 21.987-19.411 10.58-2.668 16.007-4.508 16.007-11.775 0-7.268-5.244-11.592-14.627-11.592-9.843 0-15.823 4.968-15.823 15.731h-8.739c0-15.087 9.199-22.999 24.471-22.999 14.259 0 23.459 7.544 23.459 19.319-.002 13.432-9.846 16.56-22.265 19.596zm43.976-23.735V7.544h9.292v6.439h-9.292v11.315h-8.096V13.983h-8.096V7.544h8.096v-27.23c0-9.66 3.772-13.432 12.695-13.432 1.656 0 3.772.184 5.152.552v6.624c-.828-.276-2.116-.46-3.312-.46-4.691-.001-6.439 2.207-6.439 7.543z"
						/>
					</symbol>

					{/* background */}
					<path
						css={css`
							pointer-events: ${isStatic ? 'none' : 'all'};
							cursor: ${isStatic ? 'default' : 'pointer'};

							&:focus {
								stroke: #004f31;
								stroke-width: 2;
								outline: none;
							}
						`}
						role="button"
						tabIndex={-1}
						{...hoverProps}
						{...pressProps}
						fill="#004F31"
						d="m1543.516 468.428 74.209-138.109L1154.574 81.46 1050.49 275.17 587.897 26.609l-281.738 524.34 463.151 248.86 84.325-156.936 274.923 147.721-84.324 156.936 635.182 341.296 311.613-579.94-447.513-240.458zm-456.79 505.453-97.754 181.928-287.697-154.585-316.611 589.242 493.254 265.035 287.249-534.597 461.627 248.041 127.116-236.574-667.184-358.49zM222.433 509.512 18.788 888.513l573.781 308.304 203.645-379.001-573.781-308.304z"
					/>
					{/* pictures */}
					{!!inView && (
						<g>
							<clipPath id="flower-clip-5340">
								<path
									d="M192.468 527.506h430.248v651.364H192.468z"
									transform="rotate(-61.751 407.57471 853.17778)"
									overflow="visible"
								/>
							</clipPath>
							<g clipPath="url(#flower-clip-5340)">
								<image
									xlinkHref="/_resources/img/007-DSC05340.jpg"
									width="550"
									height="379"
									overflow="visible"
									transform="rotate(27.18 -891.08912 657.24581) scale(1.2742)"
								/>
							</g>

							<clipPath id="flower-clip-5309">
								<path
									d="M381.076 150.159h595.238v525.776H381.076z"
									transform="rotate(-61.751 678.6783 413.0482)"
									overflow="visible"
								/>
							</clipPath>
							<g clipPath="url(#flower-clip-5309)">
								<image
									xlinkHref="/_resources/img/002-DSC05309.jpg"
									width="418"
									height="392"
									overflow="visible"
									transform="matrix(.7363 -1.2877 1.2877 .7363 275.7558 538.2725)"
								/>
							</g>

							<clipPath id="flower-clip-5356">
								<path
									d="m989.004 1155.833-287.697-154.585-316.612 589.242 493.254 265.036 287.249-534.597 29.363-54.646z"
									overflow="visible"
								/>
							</clipPath>
							<g clipPath="url(#flower-clip-5356)">
								<image
									xlinkHref="/_resources/img/009-DSC05356.jpg"
									width="529"
									height="645"
									overflow="visible"
									transform="matrix(1.0282 .5304 -.5304 1.0282 688.723 944.9607)"
								/>
							</g>

							<clipPath id="flower-clip-5312">
								<path
									d="m1753.941 1332.396-667.184-358.491-97.753 181.928 205.557 110.45-29.363 54.646 461.627 248.04z"
									overflow="visible"
								/>
							</clipPath>
							<g clipPath="url(#flower-clip-5312)">
								<image
									xlinkHref="/_resources/img/003-DSC05312.jpg"
									width="241"
									height="567"
									overflow="visible"
									transform="rotate(118.252 532.54194 1226.0399) scale(1.6438)"
								/>
							</g>

							<clipPath id="flower-clip-5332">
								<path
									d="M1154.666 81.483 853.168 642.597l275.481 148.021 187.669 100.838 227.289-423.004 74.208-138.109z"
									overflow="visible"
								/>
							</clipPath>
							<g clipPath="url(#flower-clip-5332)">
								<image
									xlinkHref="/_resources/img/005-DSC05332.jpg"
									width="500"
									height="588"
									overflow="visible"
									transform="matrix(1.0245 .6645 -.6645 1.0245 1181.3397 19.13)"
								/>
							</g>

							<clipPath id="flower-clip-5363">
								<path
									d="m1543.607 468.452-227.289 423.004-187.669-100.838-84.324 156.936 635.182 341.296 311.613-579.941z"
									overflow="visible"
								/>
							</clipPath>
							<g clipPath="url(#flower-clip-5363)">
								<image
									xlinkHref="/_resources/img/010-DSC05363.jpg"
									width="601"
									height="593"
									overflow="visible"
									transform="rotate(36.94 279.49007 2323.86938) scale(1.2967)"
								/>
							</g>
						</g>
					)}
				</svg>
			</div>
		</div>
	);
});

export default Flower;
