/** @jsxImportSource @emotion/react */
import { useMemo, forwardRef } from 'react';
import { useLocation } from 'react-router-dom';

import Link from './Link';
import { navItemLinkStyle, navItemInnerStyle } from '.';

const NavLink = forwardRef(
	(
		{
			size = 'l',
			ignoreSection,
			href,
			label,
			closeButtonProps = {},
			closeButtonRef = () => {},
			isCurrent,
			isUppercase,
			...props
		},
		ref
	) => {
		const { pathname } = useLocation();

		const linkingMode = useMemo(() => {
			if (isCurrent || pathname === href) return 'current';
			if (!ignoreSection && pathname.indexOf(href) === 0) return 'section';
			return 'link';
		}, [isCurrent, href, pathname, ignoreSection]);

		const inner = <span css={(theme) => navItemInnerStyle(theme, size, isUppercase)}>{label}</span>;

		return linkingMode === 'current' ? (
			<button
				{...closeButtonProps}
				ref={closeButtonRef}
				css={(theme) => [theme.resetButton, navItemLinkStyle(theme, linkingMode)]}
			>
				{inner}
			</button>
		) : (
			<Link css={(theme) => navItemLinkStyle(theme, linkingMode)} href={href} ref={ref} {...props}>
				{inner}
			</Link>
		);
	}
);

export default NavLink;
