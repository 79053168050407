/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { lazy, memo, Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import SubNav from '../components/navigation/SubNav';
import useApi from '../api/use';
import LoadingScreen from '../components/LoadingScreen';
import ErrorScreen from '../components/ErrorScreen';
import ErrorBoundary from '../ErrorBoundary';
const Page = lazy(() => import('./Page'));
const ArticleOverview = lazy(() => import('./ArticleOverview'));
const NetworkOverview = lazy(() => import('./NetworkOverview'));
const ArticlesSegments = lazy(() => import('./ArticlesSegments'));
const Article = lazy(() => import('./Article'));

const makeAbsoluteLink = (link) => {
	if (link === '' || link === '/') {
		return document.baseURI;
	}
	return `${document.baseURI}${link.substring(1)}`;
};

const pickComponent = (className) => {
	if (!className) {
		return null;
	}
	switch (className) {
		case 'Article':
		case 'Literature':
			return Article;
		case 'ArticleCategory':
		case 'ArticleCategoryHolder':
		case 'LiteratureHolder':
			return ArticleOverview;
		case 'NetworkHolder':
			return NetworkOverview;
		case 'NetworkCategory':
			return ArticlesSegments;
		default:
			return Page;
	}
};

export const PageWrap = () => {
	const location = useLocation();

	const {
		state: {
			page: {
				vitality: { loading, error, loaded },
				...page
			},
			site: { canonicalDomain },
		},
		actions: { getPage, getInitial },
		initialized,
	} = useApi();

	useEffect(() => {
		if (!initialized.current) {
			getInitial(location.pathname, location.search);
			return;
		}
		getPage(location.pathname, location.search);
	}, [initialized, location.pathname, location.search, getPage, getInitial]);

	const Comp = pickComponent(page.className);

	return (
		<>
			<div
				className="auto-focus"
				aria-label={page?.title}
				css={css`
					&:focus-visible {
						outline: none;
					}
				`}
			/>
			<main
				css={(theme) => css`
					padding-top: ${theme.dimension.header.height.s};

					@media (min-width: ${theme.breakpoint.m}) {
						padding-top: ${theme.dimension.header.height.m};
					}
				`}
			>
				<SubNav />
				<Routes>
					<Route
						path="*"
						element={
							<ErrorBoundary>
								{!!loaded && (
									<Helmet>
										<title>{page.title}</title>
										<meta name="description" content={page.description || ''} />
										<meta name="canonical" content={`${canonicalDomain}${page.$url}`} />
										{page.lang.map(({ locale, link }) => (
											<link
												key={link}
												rel="alternate"
												hreflang={locale.replace('_', '-').toLowerCase()}
												href={makeAbsoluteLink(link)}
											/>
										))}
									</Helmet>
								)}
								<Suspense fallback={<LoadingScreen />}>{!!Comp && <Comp page={page} />}</Suspense>
								{!!loading && <LoadingScreen />}
								{!!error && <ErrorScreen />}
							</ErrorBoundary>
						}
					/>
				</Routes>
			</main>
		</>
	);
};

export default memo(PageWrap);
