/** @jsxImportSource @emotion/react */

export const Typography = ({
	as: Component = 'div',
	font = 'default',
	size = 's',
	weight = 'regular',
	lineHeight,
	fontStyle = 'normal',
	textDecoration = 'none',
	textTransform = 'initial',
	...props
}) => {
	return (
		<Component
			css={(theme) =>
				theme.typography.getStyle({
					theme,
					font,
					weight,
					size,
					lineHeight,
					style: fontStyle,
					decoration: textDecoration,
					textTransform,
				})
			}
			{...props}
		/>
	);
};
