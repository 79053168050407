import { useCallback, forwardRef } from 'react';

import { useNavigate } from '../../api/use';

/*
 * when clicking an internal link, we first have to load the page content
 * and replace history afterwards.
 * otherwise, scroll restore would fire too soon
 * please use this component for all internal links
 */
const Link = forwardRef(({ href, children, replace, jumpHistory, onNavigate = () => {}, ...props }, ref) => {
	const navigate = useNavigate();

	const onClick = useCallback(
		(evt) => {
			evt.preventDefault();
			navigate(href, { onNavigate, jumpHistory, options: { replace } });
		},
		[href, navigate, onNavigate, replace, jumpHistory]
	);

	return (
		<a href={href} onClick={onClick} ref={ref} {...props}>
			{children}
		</a>
	);
});

export default Link;
