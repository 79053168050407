/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { OverlayContainer, useOverlay, VisuallyHidden } from 'react-aria';
import { useRef } from 'react';

const barKeyframes = keyframes`
	0% { right: 100%;left: 0%; }
	50% { right: 0%;left: 0%; }
	100% { right: 0%;left: 100%; }
`;

const LoadingScreen = () => {
	const ref = useRef();
	const { overlayProps, underlayProps } = useOverlay({ isOpen: true, isDismissable: false }, ref);
	return (
		<OverlayContainer>
			<div
				ref={ref}
				css={(theme) => css`
					position: fixed;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					background: ${theme.color.loadingBackground};
					margin: 0;
					padding: 0;
					z-index: ${theme.zIndex.loadingScreen};
				`}
				{...underlayProps}
			>
				<div
					css={css`
						width: 100%;
						height: 100%;
						background: transparent;
						margin: 0;
						padding: 0;
					`}
					{...overlayProps}
				>
					<VisuallyHidden>Loading...</VisuallyHidden>
					<div
						css={(theme) => css`
							position: absolute;
							left: 0;
							right: 0;
							height: 4px;
							bottom: 0;
							background: ${theme.color.primary};
							animation: ${barKeyframes} 1000ms linear;
							animation-iteration-count: infinite;
						`}
					/>
				</div>
			</div>
		</OverlayContainer>
	);
};

export default LoadingScreen;
